import React from 'react';
import { Button, Center, Accordion, Container } from '@mantine/core';
import { BrandSpotify } from 'tabler-icons-react';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;

export default function Login() {
    return (
        <div>
            <Container size="sm">
                <Center mt={100}>
                    <Button component="a" color="green" href={AUTH_URL} size="lg" variant="outline" rightIcon={<BrandSpotify size={14} />}>
                        Login With Spotify{' '}
                    </Button>
                </Center>

                <Accordion defaultValue="what" mt={100}>
                    <Accordion.Item value="what">
                        <Accordion.Control>What does the Website?</Accordion.Control>
                        <Accordion.Panel>After logging in with your Spotify account, you will see a music video that matches the song you are listening to on Spotify</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="why">
                        <Accordion.Control>Why am I not seeing a music video?</Accordion.Control>
                        <Accordion.Panel>
                            Since Spotify only allows a certain number of users on a hobby project, I have to give each user separate access. If you want to use the website, write me an{' '}
                            <a href={`mailto:dev@danielmuenstermann.de`}>email</a> with your first and last name and the email address you use at Spotify.
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="problem">
                        <Accordion.Control>There is another problem with the website</Accordion.Control>
                        <Accordion.Panel>The website may crash for some users. If this happens, a simple reload of the page should be enough to solve the problem.</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </div>
    );
}
