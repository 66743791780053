import React, { Component } from 'react';
import ReactPlayer from 'react-player/youtube';
import { Card, Image, Text } from '@mantine/core';

class Player extends Component {
    ref = (player) => {
        this.player = player;
    };

    handlePlay = () => {
        this.player.seekTo(3, 'seconds');
    };

    render() {
        return (
            <div className="holder">
                <div>
                    <ReactPlayer
                        ref={this.ref}
                        allow="autoplay"
                        muted={true}
                        playing={this.props.playing}
                        url={this.props.url}
                        width={this.props.width}
                        height={this.props.height}
                        onStart={this.handlePlay}
                        className="frame"
                    />
                </div>
                <div className="bar" id="Card">
                    <Card>
                        <Card.Section>
                            <Image src={this.props.spotifyData.album_cover} height={160} />
                        </Card.Section>
                        <Text weight={500}>Trackname: {this.props.spotifyData.track_name}</Text>
                        <Text>Artists: {this.props.spotifyData.artists}</Text>
                    </Card>
                </div>
            </div>
        );
    }
}
export default Player;
