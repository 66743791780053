import { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from './useAuth';
import SpotifyWebApi from 'spotify-web-api-node';
import { Button, Center } from '@mantine/core';
import { Resize, PlayerPlay, PlayerStop } from 'tabler-icons-react';
import { useViewportSize, useFullscreen, useScrollLock } from '@mantine/hooks';
import Player from './Player';

function Dashboard({ code }) {
    const accessToken = useAuth(code);
    const [spotifyData, setSpotifyData] = useState([]);
    const [startStop, setStartStop] = useState(false);
    const [playing, setPlaying] = useState(false);
    const { toggle, fullscreen } = useFullscreen();
    const spotifyApi = new SpotifyWebApi({
        clientId: process.env.REACT_APP_CLIENT_ID,
    });

    useScrollLock(true);

    useEffect(() => {
        if (!accessToken) return;
        spotifyApi.setAccessToken(accessToken);
    }, [accessToken]);

    useEffect(() => {
        if (!accessToken) return;

        if (startStop) {
            setPlaying(true);

            async function getSpotifyData() {
                await axios
                    .post(process.env.REACT_APP_API_URL + '/get-spotify-data', {
                        accessToken: accessToken,
                    })
                    .then((result) => {
                        setSpotifyData(result.data);
                    });
            }

            getSpotifyData();

            const interval = setInterval(() => getSpotifyData(), 500);

            return () => {
                clearInterval(interval);
            };
        } else {
            setPlaying(false);
        }
    }, [accessToken, startStop]);

    const { height, width } = useViewportSize();

    const [oldLink, setOldLink] = useState('');
    const [newLink, setNewLink] = useState('');

    useEffect(() => {
        setNewLink(spotifyData.link);

        if (oldLink !== newLink) {
            setOldLink(spotifyData.link);
        }
    }, [spotifyData]);

    return (
        <div>
            {fullscreen ? (
                <p className="hide"></p>
            ) : (
                <Center mt="sm" mb="sm">
                    <div>
                        <Button leftIcon={<Resize size={14} />} variant="outline" onClick={toggle} color={fullscreen ? 'red' : 'blue'} m="sm">
                            {fullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
                        </Button>
                        {startStop ? (
                            <Button onClick={() => setStartStop(false)} variant="outline" leftIcon={<PlayerStop size={14} />} color="red" m="sm">
                                Stop
                            </Button>
                        ) : (
                            <Button onClick={() => setStartStop(true)} variant="outline" leftIcon={<PlayerPlay size={14} />} color="green" m="sm">
                                Start
                            </Button>
                        )}
                    </div>
                </Center>
            )}

            <Player playing={playing} url={oldLink} width={width} height={height} spotifyData={spotifyData} />
        </div>
    );
}

export default Dashboard;
